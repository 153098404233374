.full-width {
    width: 100%;
}

.gallery-image {
    position: relative;
    min-height: 100px;
}

.gallery-image .top-left {
    position: absolute;
    top: 8px;
    left: 8px;
}

.gallery-image .top-right {
    position: absolute;
    top: 8px;
    right: 8px;
}

.gallery-image .middle-center {
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.gallery-image .gallery-image-buttons {
    position: absolute;
    bottom: 5px;
    right: 5px;
    left: 5px;
    background-color: rgba(255,255,255, 0.75);
}

.gallery-image .gallery-image-buttons .checkbox {
    margin: 3px 0;
    padding: 0 3px;
}

.gallery-image .gallery-image-buttons .checkbox .text {
    font-weight: bold;
}
